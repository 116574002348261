/** @jsx jsx */
import "../fonts/stylesheet.css";

import React, { memo } from "react";
import { jsx, Styled, ThemeProvider } from "theme-ui";

import Homepage from "../components/Homepage";
import SEO from "../components/SEO";
import themeAquatic from "../theme-aquatic";

const IndexPage: React.FC<Record<string, unknown>> = () => {
  return (
    <ThemeProvider theme={themeAquatic}>
      <Styled.root>
        <SEO />
        <Homepage />
      </Styled.root>
    </ThemeProvider>
  );
};

export default memo(IndexPage);
